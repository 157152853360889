import { firestore } from "../../../firebaseConfig";
import {
  doc,
  getDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const translationsKeys = [
  "Anasayfa",
  "Giriş Yap",
  "Kayıt Ol",
  "Çıkış Yap",
  "Dil Ekle",
  "Dil Seçimi",
  "Dil Adı",
  "Çeviriler & Diller",
];

const emptyParagraphValues = {
  FAQ_1: {
    OriginalQuestion: "Konuşma Sesi Bozuklukları Nedir",
    OriginalAnswer:
      "Çocuğunuz konuşmayı öğrenme aşamasında sözcükleri söylerken bazı hatalar yapabilir. Bu hataların bir kısmı konuşma gelişiminin bir parçasıdır ve belirli yaşlarda çocukların bu hataları yapmaları beklenir. Ancak zamanla bu hataların ortadan kaybolması ve çocuğunuzun sizin gibi anlaşılır bir konuşmaya geçmesi gerekir. Eğer çocuğunuz 4 yaş ve üzerindeyse ve hala bazı sesleri üretmede güçlükler yaşıyorsa, o zaman konuşma sesi bozukluğundan şüphelenebilirsiniz. Konuşma Sesi Bozuklukları (KSB), konuşma seslerini üretmede, algılamada ve/veya dili kurallarına uygun bir şekilde kullanmada yaşanan güçlükler sonucu konuşmanın anlaşılırlığının etkilenmesidir.",
    FAQ_1_Title: "",
    FAQ_1_Paragraph: "",
  },
  FAQ_2: {
    OriginalQuestion: "Gecikmiş Dil ve Konuşma",
    OriginalAnswer:
      "Çocuklarda konuşmanın gecikmesi, sık karşılaşılan dil ve konuşma bozukluklarından birisidir. Gecikmiş dil ve konuşma bozukluğu, çocukların konuşması beklenen zamanda konuşma becerilerini yaratıcı bir şekilde kullanamamaları ya da yaşından dolayı sahip olması gereken seviyenin altında kalmaları sonucunda karşımıza çıkmaktadır. Bu süreci tetikleyen veya neden olan birçok sebebin varlığı bilinmektedir.",
    FAQ_2_Title: "",
    FAQ_2_Paragraph: "",
  },
  FAQ_3: {
    OriginalQuestion: "Afazi Nedir",
    OriginalAnswer:
      "Afazi, serebral emboli veya beyin kanaması sonucunda oluşan inme (strok) ya da beynin sol hemisferini etkileyen kafa travması ve benzeri yaralanmalar sonucunda ortaya çıkan ve beynin dilden sorumlu alanlarının hasarlanmasından kaynaklanan edinilmiş bir dil bozukluğudur. Bu duruma bağlı olarak bireyin konuşma, konuşulanı anlama, tekrarlama, adlandırma, okuma ve yazma gibi becerilerinde bozulmalar oluşmaktadır. Afazi tipleri, genel olarak akıcı ve tutuk afazi olmak üzere iki şekilde görülmektedir; yani bir kısım afazili vakaların anlamaları iyi ama konuşmaları tutuk, bir kısmının da anlamaları kötü, konuşmaları ise akıcı ama anlamsızdır.",
    FAQ_3_Title: "",
    FAQ_3_Paragraph: "",
  },
  FAQ_4: {
    OriginalQuestion: "Çocukluk Çağı Konuşma Apraksisi",
    OriginalAnswer:
      "Karşımızdakine bir şey söylemek istediğimizde beynimiz, o sözcüğü oluşturan sesleri sıralı ve doğru şekilde üretebilmek için planlama yapıp, gerekli kasları uyarmaktadır. Sıralı hareketler, sözcüğü doğru üretebilmek için konuşmaya yardımcı olan organların doğru zamanda doğru üretim yerlerini seçerek çalışması gibi düşünülebilir. Örneğin ‘ot’ sözcüğünü sesletebilmek için, önce dudaklar yuvarlak pozisyon alarak /o/ sesini, daha sonra dilin ucunu dişlerin arkasıyla temas ettirerek /t/ sesini sıralı şekilde üretmesi gerekir ve bu olay çok kısa bir zamanda gerçekleşir. Dolayısıyla, kas yapısı zarar görmemesine rağmen konuşma üretimi için gereken sıralı hareketlerin motor planlaması ve programlamasındaki bir bozukluktan kaynaklanan bu durum Çocukluk Çağı Konuşma Apraksisi (ÇÇKA) olarak adlandırılır. ÇÇKA olan çocuklar sesleri, heceleri ve sözcükleri istemli üretmede sorun yaşarlar. Bu çocuklar aslında ne söylemek istediğini bilmekte fakat beyin bu sözcükleri söyleyebilmek için gerekli kas hareketlerini yönetmekte zorlandığı için çocuklarda konuşma ile ilgili bir bozukluk ortaya çıkmaktadır.",
    FAQ_4_Title: "",
    FAQ_4_Paragraph: "",
  },
  FAQ_5: {
    OriginalQuestion: "Ses Bozukluğu Nedir",
    OriginalAnswer:
      "Ses bozukluğu, sesin normalden farklı bir şekilde üretilmesi, tınısının, yüksekliğinin, şiddetinin veya süresinin kişinin yaşına, cinsiyetine ve kültürel normlara uygun olmayan bir şekilde bozulması durumudur. Bu durum konuşmayı zorlaştırabilir, anlaşılmayı engelleyebilir ve bireyin sosyal, mesleki veya duygusal yaşamını olumsuz etkileyebilir. Ses bozuklukları genellikle ses tellerindeki sorunlardan, yanlış ses kullanımından, enfeksiyonlardan, travmalardan veya nörolojik hastalıklardan kaynaklanabilir. Tedavisi, genellikle bir konuşma ve dil terapisti veya KBB uzmanı eşliğinde yapılır.",
    FAQ_5_Title: "",
    FAQ_5_Paragraph: "",
  },
  FAQ_6: {
    OriginalQuestion: "Gelişimsel Dil Bozukluğu Nedir",
    OriginalAnswer:
      "Çocuğunuz konuşmayı öğrenme aşamasında sözcükleri söylerken bazı hatalar yapabilir. Bu hataların bir kısmı konuşma gelişiminin bir parçasıdır ve belirli yaşlarda çocukların bu hataları yapmaları beklenir. Ancak zamanla bu hataların ortadan kaybolması ve çocuğunuzun sizin gibi anlaşılır bir konuşmaya geçmesi gerekir. Eğer çocuğunuz 4 yaş ve üzerindeyse ve hala bazı sesleri üretmede güçlükler yaşıyorsa, o zaman konuşma sesi bozukluğundan şüphelenebilirsiniz. Konuşma Sesi Bozuklukları (KSB), konuşma seslerini üretmede, algılamada ve/veya dili kurallarına uygun bir şekilde kullanmada yaşanan güçlükler sonucu konuşmanın anlaşılırlığının etkilenmesidir.",
    FAQ_6_Title: "",
    FAQ_6_Paragraph: "",
  },
  FAQ_7: {
    OriginalQuestion: "Akıcılık Bozuklukları",
    OriginalAnswer:
      "Kekemelik, konuşma akıcılığının; (a) ses, hece veya tek heceli sözcük tekrarı, (b) seslerin uzatılması ve (c) hava akışında veya seslemede bloklarla, olağandışı yüksek sıklıkla ve/veya uzun süreli kesintilere uğramasıdır. Belirtilen konuşma özelliklerine ek olarak yüz buruşturma, göz kırpma, el vurma gibi ikincil davranışlar kekemeliğe sıklıkla eşlik etmektedir. Ayrıca, kekemeliği olan bireyler, kekemeliğe yönelik olumsuz tutum ve duyguları nedeniyle bazı ses ve sözcüklere yönelik sözcük değiştirme, erteleme gibi sözcük kaçınmaları ya da belirli konuşma ortamlarından uzak durarak durumsal kaçınma davranışları göstermektedirler. Hızlı Bozuk Konuşma ya da kısa adı ile HBK henüz tam olarak tanımlanmış bir sorun değildir. Bu duruma karşın çoğunlukla hızlı ve anlaşılmayan ya da büyük oranda dikkat gösterildiği halde anlaşılır olması mümkün olmayan konuşma bozukluklarına denmektedir. Konuşmaların anormal düzeyde hızlı ve yüksek düzeyde anlaşılır olmaması durumlarında ortaya çıkan bu sorunu yaşayan bireylerde; ses, hece, kelime veya sözcük örüntüleri farklılıklar içermektedir. Bu konuşma bozukluğunda en sık gözlemlenen durum kelimelerin ya da cümlelerin yayılması olduğu gibi aynı zamanda ani duraksamalar ya da kelimelerin yutulması gibi durumlar da ortaya çıkabilir.",
    FAQ_7_Title: "",
    FAQ_7_Paragraph: "",
  },
  FAQ_8: {
    OriginalQuestion: "Otizm Spektrum Bozukluğu",
    OriginalAnswer:
      "OSB, erken çocukluk döneminde ortaya çıkan, kişinin sözel ya da sözel olmayan iletişimini, sosyal ilişkilerini ve regülasyonunu olumsuz etkileyen ve sınırlı ilgi ile tekrarlı davranışları içeren karmaşık bir nörogelişimsel farklılıktır. Otizm kişileri farklı şekillerde ve değişen boyutlarda etkileyen bir durumdur. O sebeple “spektrum bozukluğu” olarak adlandırılır. Bazı otizmli bireyler öğrenme, düşünme ve problem çözme konusunda ciddi zorluklar yaşarken bazıları bu konularda yüksek başarı sergileyebilir. Benzer şekilde bazı otizmli bireyler günlük yaşam becerilerini sürdürebilmek için desteğe ihtiyaç duyarken bazıları tamamen bağımsız olarak yaşamaktadır.",
    FAQ_8_Title: "",
    FAQ_8_Paragraph: "",
  },
  FAQ_9: {
    OriginalQuestion: "Özgül Öğrenme Güçlüğü Nedir",
    OriginalAnswer:
      "Yaşa uygun zekâ seviyesi, eğitim ve çevresel koşullara rağmen, okuma, yazma ve aritmetik gibi akademik becerileri öğrenme ve kullanmada meydana gelen beklenmedik ve hayat boyu devam eden nörogelişimsel bozuklukları kapsayan şemsiye bir terimdir.",
    FAQ_9_Title: "",
    FAQ_9_Paragraph: "",
  },
  FAQ_10: {
    OriginalQuestion: "Yutma Bozukluğu Nedir",
    OriginalAnswer:
      "Yutma bozukluğu her yaş grubunda çeşitli hastalıklara bağlı olarak ortaya çıkabilir: Sinir sistemi hasarları: inme, kafa travması, beyin hasarı, omurilik hasarı, Parkinson, Multipl Skleroz, Serebral Palsi, Amiyotrofik Lateral Skleroz, müsküler distrofi, kas hastalıkları Alzheimer, vb, Baş boyun bölgesi ile ilgili hasarlar: ağız temizliğinde sorunlar; ağız içi, dil, yutak, ya da gırtlak kanserleri ve cerrahisi ile radyasyon etkisi vb. Reflü, yemek borusu darlığı gibi gastroözefageal sorunlar ve özellikle çocuklardaki emme-yutma-solunum koordinasyon problemleri, psikolojik nedenler yanı sıra birçok hastalık da yutma bozukluğu ile ilişkili olabilir.",
    FAQ_10_Title: "",
    FAQ_10_Paragraph: "",
  },
  FAQ_11: {
    OriginalQuestion: "Crocodil Nedir",
    OriginalAnswer: `Crocodil, dil ve konuşma terapistleri ile ailelerin terapi sürecini optimize etmek amacıyla özel olarak tasarlanmış bir web platformudur. Hızla gelişen sağlık hizmetleri dünyasında, konuşma terapisi merkezlerinin başarısı ve büyümesi için etkin hasta yönetimi çok önemlidir. Hasta iletişimini kolaylaştıran, otomasyonu geliştiren, terapi adımlarını daha görünür ve kontrol edilebilir kılan bu sistem geleneksel terapi takip ve kayıt anlayışlarınızı değiştirerek terapi oturumlarınızın kalitesini artırmak için size yardımcı olacak.`,
    FAQ_11_Title: "",
    FAQ_11_Paragraph: "",
  },
  FAQ_12: {
    OriginalQuestion: "Nasıl Danışan Eklerim",
    OriginalAnswer: `Sistemimiz içerisinden danışanlarınızın maillerini ekleyerek danışanlarınızın sisteme kaydını yapmış olursunuz. Danışanınızın size verdiği maile gelen şifre ile danışan sistemine girebilir ve sürece dahil olmuş olur. Dilerse şifresini daha sonra değiştirebilir.`,
    FAQ_12_Title: "",
    FAQ_12_Paragraph: "",
  },
  FAQ_13: {
    OriginalQuestion: "Ödemeyi kim yapar",
    OriginalAnswer:
      "Ödemeler terapist tarafından yapılır. Danışandan bir ödeme talep edilmez.",
    FAQ_13_Title: "",
    FAQ_13_Paragraph: "",
  },
  FAQ_14: {
    OriginalQuestion: "Danışanlarımı eklerken hangi bilgiler gereklidir",
    OriginalAnswer:
      "Danışan eklerken danışanınızın adı soyadı, maili ve varsa bakım vereninin adı soyadı lazım. Ve tabi bir iletişim numarası. İşte bu kadar.",
    FAQ_14_Title: "",
    FAQ_14_Paragraph: "",
  },
  FAQ_15: {
    OriginalQuestion: "Crocodili neden kullanmalıyım",
    OriginalAnswer:
      "Sağlık hizmeti uygulamalarının karşılaştığı en büyük zorluklar, hasta kayıtlarını tutmak ve ilerlemelerini izlemek ve danışanla etkili iletişimi artırmak gibi zahmetli görevlerdir. Crocodil terapi takip ve kayıt sistemi bu aşamaları sizin için yerin getirir. Danışan/Terapist arasındaki etkili iletişim danışan memnuniyetini ve danışan tutma oranlarını doğrudan etkilediği için önem taşır. Crocodil içerisindeki yenilikleri ve kullanıcı dostu çözümleriyle terapi oturumlarından alınan memnuniyeti artırmak ve etkili iletişimi sağlamak için gerekli olan sistemi ve çözümleri içerir.",
    FAQ_15_Title: "",
    FAQ_15_Paragraph: "",
  },
  FAQ_16: {
    OriginalQuestion: "Ödemeler nasıl alınıyor",
    OriginalAnswer:
      "Aylık abonelik yöntemine göre her ay belirlenen tarihte ödemeler alınır.",
    FAQ_16_Title: "",
    FAQ_16_Paragraph: "",
  },
  FAQ_17: {
    OriginalQuestion: "Kayıtta istenen bilgiler nelerdir",
    OriginalAnswer:
      "Kayıt için istediğimiz bilgiler mailiniz ve terapist olduğunuzu belgeleyecek birkaç sorudur.",
    FAQ_17_Title: "",
    FAQ_17_Paragraph: "",
  },
  About_us: {
    Original_Title: "Hakkımızda",
    Original_Paragraph: `Crocodil, dil ve konuşma terapistleri ile ailelerin terapi
              sürecini optimize etmek amacıyla özel olarak tasarlanmış bir web
              platformudur. İhtiyaçlarınızı en iyi şekilde karşılamak için
              geliştirilen bu sistem, kullanıcı dostu arayüzüyle kolaylıkla
              kullanılabilir. Misyonumuz, dil ve konuşma terapisi alanında
              hizmet veren profesyonellere teknolojik bir çözüm sunarak işlerini
              daha etkin ve verimli bir şekilde yapmalarına yardımcı olmaktır.
              Aynı zamanda ailelere de evdeki terapi sürecini daha organize bir
              şekilde takip edebilmeleri için bir platform sağlamaktayız.
              Crocodil'in sunduğu temel özellikler arasında hasta kaydı, takibi
              ve ilerlemesinin izlenmesi yer almaktadır. Sistemde her hasta için
              ayrıntılı profiller oluşturulabilir ve bu profillerde hastanın
              geçmiş kayıtları, tedavi planları ve ilerlemesi gibi bilgiler
              kolayca erişilebilir haldedir. Ayrıca uygulamamızda yer alan sesli
              not alma özelliği sayesinde terapistlerin oturumlar sırasında not
              tutması da oldukça pratiktir. Crocodil'in güvenlik önlemleri de en
              üst düzeydedir. Tüm hasta bilgileri şifreli bir şekilde
              saklanmakta olup, yalnızca yetkili kişiler tarafından erişilebilir
              durumdadır. Böylece gizlilik ihlali riski minimuma indirgenmiştir.
              Ayrıca Crocodil'i kullanarak hem dijital içeriklerle hem de
              interaktif egzersizlerle hastalarınıza daha çeşitli terapi
              seansları sunabilirsiniz. Öğretici videolar, oyunlar ve testler
              gibi materyaller ile hastaların motivasyonunu arttırabilir ve daha
              etkili sonuçlar elde edebilirsiniz. Bizim için en önemli unsur ise
              sizin memnuniyetinizdir. Müşterilerimize destek sağlamak amacıyla
              Crocodil üzerinde her zaman kullanıcı desteği sunuyoruz.
              Sorularınız veya problemleriniz olduğunda size yardım etmek için
              buradayız. Crocodil olarak dil ve konuşma terapisinin gücünü
              keşfetmek isteyen tüm profesyonelleri bekliyoruz! Bizimle çalışmak
              için kaydolun ve hastalarınıza daha iyi bir gelecek sunmak için
              bugün adım atın!`,

    About_us_Title: "",
    About_us_Paragraph: "",
  },
  Content_Section: {
    OriginalQuestion: "Dil ve Konuşma Terapisi",
    OriginalAnswer:
      "İletişim, dil, konuşma, ses ve yutma sağlığının korunması, bozuklukların önlenmesi, değerlendirmesi, tanılaması, rehabilitasyonu ve bilimsel araştırılması ile uğraşan, sanat ve bilimi birleştiren, insanın iyi olma haline adanmış bir sağlık disiplinidir.",
    Content_Section_Title: "",
    Content_Section_Paragraph: "",
  },
};

const emptyExerciseValues = {
  EX_1: {
    OriginalTitle: "1.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "Bebek",
    OriginalParagraph: `Nihal sarı saçlı bebeğini çok severdi. Okuldan gelir gelmez gözü bebeğini arardı. Adını buğday koymuştu bebeğinin. Ailesi, bebeğin ismine çok gülmüştü. Ama Nihal koyduğu ismi çok beğeniyordu. Bebeğinin saçlarını tarlalarındaki buğdaylara benzetiyordu.
Hasat zamanıydı. Ağustosun en sıcak günleriydi. Nihal, buğdayları biçen ailesini seyrediyordu. Tarlanın kıyısındaki ağacın altındaydı. Gölgede bebeği ile oynarken uyuyakaldı. Uyandığında buğday yok olmuştu (Ergeneci, 1999)`,
    EX_1_Title: "",
    EX_1_Subtitle: "",
    EX_1_Paragraph: "",
  },
  EX_2: {
    OriginalTitle: "2.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "Öküz Olmak İsteyen Kurbağa",
    OriginalParagraph: `Kurbağanın biri bir gün dere kenarına gelmiş. Canı çok sıkılıyormuş. Derede kendi etrafında oradan oraya zıplıyormuş. Keyifle çevresine bakmaya başlamış. Tam o sırada çayırda otlayan öküzü görmüş. Öküzün büyüklüğüne hayran olmuş. Kendi kendine:
-bende öküz gibi olabilirim. O zaman herkes benim büyüklüğüme hayran olur, demiş.
Bunun üzerine kurbağa ne yapabilirim diye düşünmeye başlamış birden aklına parlak bir fikir gelmiş ve kendini şişirmeye başlamış. Şiştikçe şişmiş, şiştikçe şişmiş. Biraz daha biraz daha derken çat diye çatlayıvermiş. Öküz olmak isterken ölü bir kurbağa oluvermiş (Ergeneci, Yurtkulu, 2002)`,
    EX_2_Title: "",
    EX_2_Subtitle: "",
    EX_2_Paragraph: "",
  },
  EX_3: {
    OriginalTitle: "3.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "HAVA TAHMİNLERİ",
    OriginalParagraph: `Hava durumu ile ilgili bilgilere ulaşmak bizler için oldukça önemlidir. İnternetten, televizyon, gazete ve ya radyodan hava durumlarındaki değişiklikleri öğrenebiliriz. Böylece günlük işlerimizi daha rahat ayarlayabilir, hava koşullarına göre giyinebilir ve günlük planımızı daha uygun bir şekilde hazırlayabiliriz.
Ayrıca birçok meslek dalı için hava durumunu bilmek oldukça yararlıdır. Örneğin; kaptanlar, balıkçılar, pilotlar, şoförler ve tarımla ilgilenenler için hava durumunu bilmek yararlıdır. Bizler de bulutların şekline ve rengine bakarak hava durumunu tahmin edebiliriz (Ekiztepe, 2004).`,
    EX_3_Title: "",
    EX_3_Subtitle: "",
    EX_3_Paragraph: "",
  },
  EX_4: {
    OriginalTitle: "4.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "PİNTİ",
    OriginalParagraph: `Pintinin biri nesi var nesi yoksa altınla değiştirirmiş. Altını da külçe halinde götürüp gömmüş. Ama gönlünü de aklını da birlikte gömmüş. Her gün bir kez gelir, toprağı kazar, malına bakarmış.
İşçinin biri bunu uzaktan görmüş. İşi anlamış. Gelmiş, altın külçesini alıp götürmüş. Ertesi gün pinti toprağı gene kazmış. Bakmış ki altın yok. Dövünüp ağlamaya, saçlarını yolmaya başlamış.
Oradan biri geçiyormuş: -Ne var, ne oldu, diye sormuş. İşin aslını öğrenince: -Ne ağlıyorsun, be adam, demiş. Senin altının ha varmış, ha yokmuş. Git bir taş al, onu göm, altındır de, çık işin içinden. Senin için altınla taşın bir farkı mı var? Anlaşılıyor ki sen altının varken bir yararını görmüyormuşsun, demiş (Kobak, 2011).`,
    EX_4_Title: "",
    EX_4_Subtitle: "",
    EX_4_Paragraph: "",
  },
  EX_5: {
    OriginalTitle: "5.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "Tilki",
    OriginalParagraph: `Ortalık sıcaktan kavruluyordu. Tilkiciğin dili dışarı sarkmış, kendisine serin gölgelikli bir yer arıyordu. Gide gide kendisini bir meyve bahçesinde buldu. Baktı ki yüksekçe bir asmanın üzeri, iri taneli üzüm salkımlarıyla dopdolu. Ağzı sulandı, hemen asmanın dibine koştu. ‘Aman ne hoş kokusu, ne leziz tadı vardır bunların.’ diyerek asmanın dallarına doğru hücum etti.
Ancak boyu yetişemediği için üzümleri koparamadı. Asmaya tırmanmaya çalıştı, beceremedi. Tuttuğu bir dalı çekerek üzümleri kendisine yaklaştırmaya uğraştı, dal elinde kaldı. Bunun üzerine kızdı ve ‘Aman, kim bilir ne kadar da ekşidir limon gibi o üzümler.’ dedi. Sonra da arkasına bakarak çekti gitti.
Olup bitenleri asmanın üzerinden izleyen küçük kuş ise tilkinin ardından şöyle seslendi: ‘İşte böyledir! Uzanamadığın nimete ekşi der gidersin.’ (Gülpınar, 2014).`,
    EX_5_Title: "",
    EX_5_Subtitle: "",
    EX_5_Paragraph: "",
  },
  EX_6: {
    OriginalTitle: "6.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "KÜRESEL ISINMA, KÜRESEL SORUN",
    OriginalParagraph: `Dünyamızın en önemli sorunlarından birisi de ‘Küresel ısınma’dır. Küresel ısınma; Dünya’nın normal seviyesinin üzerinde ısınmasıyla meydana gelen olumsuz atmosfer olaylarına neden olmaktadır. Kısaca bahsedecek olursak, Dünyamızın ısısı karbondioksit oranının varlığına bağlıdır.
Dünya’da kullanılan bazı gazlar ozon tabakasının delinmesine, böylece karbondioksit dengesinin bozulmasına neden olmaktadır. Böylece Dünya; aşırı ısınmaya, iklim değişmelerine, çöllenmelere, kasırgalara ve buzların erimeleriyle deniz seviyelerinin değişmelerine maruz kalarak yaşanmaz bir hale gelecektir. Bu nedenle ozon tabakasına zarar veren gazların kullanılmaması için gerekli önlemleri almalıyız (Buhan, 2009).`,
    EX_6_Title: "",
    EX_6_Subtitle: "",
    EX_6_Paragraph: "",
  },
  EX_7: {
    OriginalTitle: "7.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "KİBRİTÇİ KIZ",
    OriginalParagraph: `Dehşetli soğuk vardı; kar yağıyor, akşam karanlığı bastırıyordu. Yılbaşı gecesiydi. Bu soğukta, bu karanlıkta küçük bir kızcağız yürüyordu soğukta; başı açık, yalın ayaktı. Evden çıkarken terlik giymesine giymişti, ama bunlar bir işe yaramamıştı. Karşı kaldırıma geçiyordu. Karşısına ansızın doludizgin giden iki araba çıkıverince kızcağız, telaştan bu terlikleri de kaybetti. Birini bir türlü bulamadı, ötekini de bir oğlan alıp kaçtı.
Küçük, işte yalın ayak yürüyordu; minik ayakları soğuktan morarmıştı. Eski püskü önlüğünde bir sürü kibrit vardı. Kibritlerin bir demetini de elinde tutuyordu. Gün boyu kimse ondan bir kibrit bile almamıştı. Karnı acıkmış, üşümüş, yürüyordu; zavallıcık yılgındı, ürkmüştü! Uzun, sarı saçlarına lapa lapa kar yağıyordu. Bütün pencerelerde ışıklar parıldıyor, sokaklar ne de nefis kaz kızartması kokuyordu (Deniz, 2013).`,
    EX_7_Title: "",
    EX_7_Subtitle: "",
    EX_7_Paragraph: "",
  },
  EX_8: {
    OriginalTitle: "8.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "Istanbul",
    OriginalParagraph: `İstanbul… Bir köşesinde cicili bicili apartmanları; bir başka köşesinde tenekeden ve kibrit çöpünden evleri; bir başka köşesinde de asil konakları ve yalılarıyla İstanbul. Günde bin kere yüz değiştiren ve en güzelle en çirkin arasında mekik dokuyan İstanbul…
Bu tezat ve zenginlik dünyası, her şeye rağmen tek ve yekpare birlik halinde benim bütün mekân ölçümü, bütün ruhumu billurlaştırıyor. Onun sefaletinde, zavallılığında bile hiçbir yerin devşiremeyeceği, sırrı yalnız bana belli bir mana görüyorum. Yemiş kaldırımlarının vıcık vıcık çamurunda bile Paris’in tahta kaldırımlarında bulmadığım mana ve şahsiyeti okuyorum (Canpekel, 2012).`,
    EX_8_Title: "",
    EX_8_Subtitle: "",
    EX_8_Paragraph: "",
  },
  EX_9: {
    OriginalTitle: "9.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "GÜNÜN ADAMI",
    OriginalParagraph: `Her şey yoluna girdi ve biz tekrar, hatta eskisinden daha kuvvetle günün adamı olduk. Babacanca hallerim halkın hoşuna gidiyordu. Acayip mazim, icat kabiliyetim, açık kalbim her gün bir kere daha övülüyordu. Hiçbir topluluk yoktu ki bulunmam istenilmesin! Doğrusunu isterseniz ben de şöhretin tam tadını çıkarmaktan hiç çekinmiyordum.
Gözlüğüm, şemsiyem, hiçbir zaman yerine tam oturmayan şapkam, biraz bol kesilmiş elbiselerim, babayani hallerime kadar her şeyim bu muvaffakiyeti besleyecek şekilde tanzim edilmişti. Gittiğim her yerde etrafım çevriliyor. Her meselede fikrim soruluyordu. Umuma ait ölçüleri hiç rahatsız etmeyecek şekilde yaşadığım için seviliyordum (Bektaş, 2013).`,
    EX_9_Title: "",
    EX_9_Subtitle: "",
    EX_9_Paragraph: "",
  },
};

const emptyPricingValues = {
  INC_1: {
    OriginalQuestion: "Terapi Takip Sistemi",
    OriginalAnswer:
      "Danışanlarınızı kayıt edip takibini yapın.Kendiniz için veya danışanınız için notlar alın",
    INC_1_Title: "",
    INC_1_Paragraph: "",
  },
  INC_2: {
    OriginalQuestion: "Ödevlendirme",
    OriginalAnswer:
      "Danışanlarınıza verdiğiniz ödevleri danışanlarınız kendi panelleri üzerinden takip edebilecekler.Danışanlarınız için açıklamalı fotoğraf ve videolar yükleyebileceksiniz.",
    INC_2_Title: "",
    INC_2_Paragraph: "",
  },
  INC_3: {
    OriginalQuestion: "Danışan Transfer",
    OriginalAnswer:
      "Danışanınız istediğiniz notlar ile birlikte yeni bir terapiste transfer edilebilecek",
    INC_3_Title: "",
    INC_3_Paragraph: "",
  },
  INC_4: {
    OriginalQuestion: "Danışan Paneli",
    OriginalAnswer:
      "Danışanlarınızı sisteme kayıt ettikten sonra danışanların kendilerine giden mail ile şifre koyabilecekler ve süreçlerini takip edebilecekler",
    INC_4_Title: "",
    INC_4_Paragraph: "",
  },
  INC_5: {
    OriginalQuestion: "Ödev Bilgilendirme ve Takip Sistemi",
    OriginalAnswer:
      "Ödev verdiğiniz danışanlar bilgilendirilecek ve ödev tesliminden önce uyarılacaklar",
    INC_5_Title: "",
    INC_5_Paragraph: "",
  },
  INC_6: {
    OriginalQuestion: "Farklı Araçlar",
    OriginalAnswer: "Anketler ve Kekemelik Şiddeti Hesaplayıcı",
    INC_6_Title: "",
    INC_6_Paragraph: "",
  },
};

const emptyValues = {
  Anasayfa: "",
  "Giriş Yap": "",
  "Kayıt Ol": "",
  "Kullanıcı Ayarları": "",
  "Çıkış Yap": "",
  "Zaten bir hesabınız mı var": "",
  İsim: "",
  Soyisim: "",
  Ad: "",
  Soyad: "",
  "Şirket Adı": "",
  "Üyeliği İptal Et": "",
  "Ayarları Kaydet": "",
  "Profil Resmi": "",
  "Lütfen Bekleyiniz": "",
  "Mezun Olduğunuz Okul": "",
  "Terapist Olarak Kayıt Ol": "",
  "Kullanıcı Sözleşmesini, Gizlilik Politikasını okudum": "",
  "kabul ediyorum": "",
  "Çalıştığınız Yer (Klinik adı, Home Office)": "",
  "Dil Ekle": "",
  Diller: "",
  Şifre: "",
  "Dil Seçimi": "",
  "Dil Adı": "",
  "Çeviriler & Diller": "",
  "Şifremi unuttum": "",
  "Terapist hesabı açmak mı istiyorsunuz": "",
  "KVKK VE AYDINLATMA METNİ": "",
  "TERMS & CONDITIONS": "",
  "DESTEK HATTI": "",
  "Form Ekle": "",
  "Not Ekle": "",
  "Seans Ekle": "",
  "Seans Detayları Ekle": "",
  "Seans İsmi": "",
  "Başlangıç Tarihi": "",
  "Ödev Ekle": "",
  "Uzun Vadeli Hedefi": "",
  "Kısa Vadeli Hedefi": "",
  Kaydet: "",
  Tamam: "",
  "Danışan Detayı": "",
  "Terapi Takip Sistemi": "",
  Ödevlendirme: "",
  "Geçmiş Terapiler": "",
  Notlar: "",
  "Danışanın Notları": "",
  Merhaba: "",
  "Şifre Güncelle": "",
  "Aboneliği Yönet": "",
  "Danışan Adı & Soyadı": "",
  "Danışan Ekle": "",
  "Mevcut Danışmanı Transfer Et": "",
  "Mevcut Transfer Taleplerim": "",
  "Profil Ayarları": "",
  "Mevcut Danışanlarım": "",
  "Danışan Adı": "",
  "Mevcut Danışanı Transfer Et": "",
  Telefon: "",
  "Bakım Veren Adı Soyadı": "",
  Hece: "",
  Home: "",
  Danışanlarım: "",
  "Danışan Arşivim": "",
  "Metinler & Hesap Makinesi": "",
  "Hesap Makinesini Aç": "",
  Dosyalar: "",
  Araçlar: "",
  Aylık: "",
  "Abone Ol": "",
  "Aylık Abonelik": "",
  "Pakete Dahil Olanlar": "",
  "Sıkça Sorulan Sorular": "",
  "Şiddet Eşdeğeri": "",
  Başlat: "",
  Sıfırla: "",
  Kekemelik: "",
  "Çok Şiddetli": "",
  Şiddetli: "",
  Orta: "",
  Hafif: "",
  Belirlenmemiş: "",
  "Buradan Taşıyınız": "",
  "Terapi Adı": "",
  "Genel Açıklama": "",
  "Tarih Aralığı": "",
  "Bakım Veren": "",
  "Kısa Dönem Hedefi": "",
  "Uzun Dönem Hedefi": "",
  "Tarih Başlangıç Tarihi": "",
  Açıklama: "",
  Adım: "",
  "Tamamlandı mı": "",
  "Tamamlanma Tarihi": "",
  "Fotoğrafı İndir": "",
  Danışan: "",
  "Detay Sayfası": "",
  "Aile Formu Sonuçları": "",
  "Ses Analiz Formu Sonuçları": "",
  "Son Teslim Tarihi": "",
  "Tamamlamayı İptal Et": "",
  Tamamla: "",
  "Terapi ve Görev Detayları": "",
  "Terapiye Adım Ekle": "",
};

const emptyAlertValues = {};

const emptyHeroValues = {
  HERO_Title_1: "",
  HERO_Title_2: "",
  HERO_Title_2_Bold: "",
  HERO_Paragraph: "",
};

const pricingHeaders = {
  "Tüm ihtiyaçlarınızı içeren bu pakete erişmek için aylık abonelik satın alabilirsiniz":
    "",
  "En iyi hizmet için Crocodil Abonelik Paketini İnceliyiniz": "",
  "Tüm İhtiyaçlarınız için Tek Paket": "",
};

const formTranslations = {
  Q_1: {
    OriginalQuestion: "Konuşma Sesi Bozuklukları ",
    OriginalAnswer:
      "Çocuğunuz konuşmayı öğrenme aşamasında sözcükleri söylerken bazı hatalar yapabilir. Bu hataların bir kısmı konuşma gelişiminin bir parçasıdır ve belirli yaşlarda çocukların bu hataları yapmaları beklenir. Ancak zamanla bu hataların ortadan kaybolması ve çocuğunuzun sizin gibi anlaşılır bir konuşmaya geçmesi gerekir. Eğer çocuğunuz 4 yaş ve üzerindeyse ve hala bazı sesleri üretmede güçlükler yaşıyorsa, o zaman konuşma sesi bozukluğundan şüphelenebilirsiniz. Konuşma Sesi Bozuklukları (KSB), konuşma seslerini üretmede, algılamada ve/veya dili kurallarına uygun bir şekilde kullanmada yaşanan güçlükler sonucu konuşmanın anlaşılırlığının etkilenmesidir.",
    Q_1_Title: "",
    Q_1_Paragraph: "",
  },
};

const checkoutPageValues = {
  "Fatura Adresi": "",
  "İletişim Adresi": "",
  "İletişim Bilgileri": "",
  "Ödeme ve Teslimat Bilgileri": "",
  Ad: "",
  Soyad: "",
  "Kimlik Numarası": "",
  Telefon: "",
  "İrtibat Adı": "",
  Şehir: "",
  İlçe: "",
  "Posta Kodu": "",
  Adres: "",
  "Sipariş Özeti": "",
  Toplam: "",
  "Şimdi Öde": "",
};

const alertsAndModals = {
  "Terapi detayları başarıyla kaydedildi": "",
  "Terapi detayları kaydedilirken bir hata oluştu": "",
  "Dosya Seçilemedi": "",
  "Lütfen bir dosya seçin": "",
  "Yükleme Başarısız": "",
  "Dosya yüklenirken bir hata oluştu. Lütfen tekrar deneyin": "",
  "Bir terapi seçilmedi. Lütfen adımları kaydetmeden önce bir terapi seçin": "",
  "Tüm adımlar başarıyla kaydedildi": "",
  "Adımlar Kaydedilemedi": "",
};

class LanguageService {
  constructor() {
    this.languages = this.getLanguageListFromFirebase();
  }

  async getLanguageListFromFirebase() {
    const languagesQuery = query(
      collection(firestore, "languages"),
      where("isUsable", "==", true)
    );

    const languages = await getDocs(languagesQuery);

    const languageList = languages.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    this.languages = languageList;

    return languageList;
  }

  async getLanguages() {
    return this.languages;
  }

  disableLanguage(languageId) {
    try {
      const languageRef = doc(firestore, "languages", languageId);

      updateDoc(languageRef, {
        isUsable: false,
      });

      const newLanguages = this.getLanguageListFromFirebase();

      this.languages = newLanguages;
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  createLanguage(language) {
    try {
      const languageRef = collection(firestore, "languages");

      addDoc(languageRef, language);

      const newLanguages = this.getLanguageListFromFirebase();

      this.languages = newLanguages;

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  updateLanguage(languageId, language) {
    try {
      const languageRef = doc(firestore, "languages", languageId);

      setDoc(languageRef, language);
    } catch (e) {
      console.log(e);
    }
  }

  async deleteLanguage(languageId) {
    try {
      const languageRef = doc(firestore, "languages", languageId);

      const newLanguages = this.getLanguageListFromFirebase();

      this.languages = newLanguages;

      await deleteDoc(languageRef);
    } catch (e) {
      console.log(e);
    }
  }

  async getLanguageById(languageId) {
    try {
      const languageRef = doc(firestore, "languages", languageId);

      const language = await getDoc(languageRef);

      return language.data();
    } catch (e) {
      console.log(e);
    }
  }

  async getLanguageByName(languageName) {
    try {
      const languagesQuery = query(
        collection(firestore, "languages"),
        where("name", "<=", languageName)
      );

      const languages = await getDocs(languagesQuery);

      const language = languages.docs[0];

      return language.data();
    } catch (e) {
      console.log(e);
    }
  }

  async getDictionary(ISOCODE) {
    try {
      const dictionaryRef = doc(firestore, "translations", ISOCODE);

      const dictionary = await getDoc(dictionaryRef);

      const dictionaryData = dictionary.data();

      //If dictionary is empty,undefined or null return false else return dictionary

      if (
        !dictionaryData ||
        Object.keys(dictionaryData).length === 0 ||
        dictionaryData.constructor !== Object
      ) {
        return false;
      }

      return dictionaryData;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  //TODO : Error Handling
  async updateDictionaryValue(ISOCODE, key, value) {
    try {
      const dictionaryRef = doc(firestore, "translations", ISOCODE);
      const dictionary = await getDoc(dictionaryRef);

      if (dictionary.exists()) {
        await updateDoc(dictionaryRef, { [key]: value });
      } else {
        await setDoc(dictionaryRef, { [key]: value });
      }

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async createDictionary(ISOCODE, dictionary) {
    try {
      const dictionaryRef = doc(collection(firestore, "translations"));

      addDoc(dictionaryRef, dictionary);
    } catch (e) {
      console.log(e);
    }
  }

  async deleteDictionary(ISOCODE) {
    try {
      const dictionaryRef = doc(firestore, "translations", ISOCODE);

      await deleteDoc(dictionaryRef);
    } catch (e) {
      console.log(e);
    }
  }
}

const languageService = new LanguageService();

export {
  languageService,
  translationsKeys,
  emptyValues,
  emptyPricingValues,
  emptyParagraphValues,
  emptyExerciseValues,
  pricingHeaders,
  emptyHeroValues,
  checkoutPageValues,
};
