const fields = [
  {
    label: "Doğum Tarihi",
    name: "dogum_tarihi",
    type: "date",
  },
  {
    name: "danisan_ad_soyad",
    label: "Ad Soyad",
    type: "text",
  },
  {
    name: "yas",
    label: "Yaş",
    type: "text",
  },
  {
    label: "Cinsiyet",
    name: "cinsiyet",
    options: [
      {
        label: "Erkek",
        value: "erkek",
      },
      {
        label: "Kadın",
        value: "kadın",
      },
    ],
    type: "select",
  },
  {
    label: "Eğitim Düzeyi",
    name: "egitim_duzeyi",
    options: [
      {
        label: "İlkokul",
        value: "ilkokul",
      },
      {
        label: "Ortaokul",
        value: "ortaokul",
      },
      {
        label: "Lise",
        value: "lise",
      },
      {
        label: "Üniversite",
        value: "üniversite",
      },
      {
        label: "Yüksek Lisans",
        value: "yüksek_lisans",
      },
      {
        label: "Doktora",
        value: "doktora",
      },
    ],
    type: "select",
  },
  {
    label: "Meslek",
    name: "meslek",
    type: "text",
  },
  {
    label: "Tanı",
    name: "tani",
    type: "text",
  },
  {
    label: "Değerlendirme Tarihi",
    name: "degerlendirme_tarihi",
    type: "date",
  },
  {
    name: "ses_sikayet",
    label: "Sesle ilgili şikayetler",
    type: "textarea",
  },
  {
    label: "Sigara alkol kullanımı",
    name: "sigara_alkol_kullanimi",
    options: [
      {
        label: "Kullanıyor",
        value: "kullanıyor",
      },
      {
        label: "Kullanmıyor",
        value: "kullanmıyor",
      },
    ],
    type: "radio",
  },
  {
    label: "Sinüzit, Alerji, Larenjit Varlığı",
    name: "hastalik_bilgileri",
    type: "textarea",
  },
  {
    label: "Vokal Abuse/Misuse Etmenleri",
    name: "vokal_abuse",
    type: "textarea",
  },
  {
    name: "hiz",
    label: "Hız",
    type: "radio",
    options: [
      { value: "yeterli", label: "Yeterli" },
      { value: "yavas", label: "Yavaş" },
      { value: "hizli", label: "Hızlı" },
    ],
  },
  {
    name: "siddet",
    label: "Şiddet",
    type: "radio",
    options: [
      { value: "yeterli", label: "Yeterli" },
      { value: "yavas", label: "Yavaş" },
      { value: "hizli", label: "Hızlı" },
    ],
  },
  {
    name: "rezonans",
    label: "Rezonans",
    type: "checkbox",
    options: [
      { value: "on", label: "Ön" },
      { value: "arka", label: "Arka" },
      { value: "yeterli", label: "Yeterli" },
      { value: "yetersiz", label: "Yetersiz" },
    ],
  },
  {
    name: "artikulasyon",
    label: "Artikülasyon",
    type: "radio",
    options: [
      { value: "yeterli", label: "Yeterli" },
      { value: "yetersiz", label: "Yetersiz" },
    ],
  },
  {
    name: "solunum_tipi",
    label: "Solunum Tipi",
    type: "checkbox",
    options: [
      { value: "abdominal", label: "Abdominal" },
      { value: "torasik", label: "Torasik" },
      { value: "klavikular", label: "Klavikular" },
      { value: "mikst", label: "Mikst" },
    ],
  },
  {
    name: "informal_gozlemler",
    label: "İnformal Gözlemler",
    type: "checkbox",
    options: [
      { label: "Hard Glottal Atak", value: "hard_global_atak" },
      { label: "Çenede Gerginlik", value: "cenede_gerginlik" },
      { label: "Gözlenen Gerginlik", value: "gozlenen_gerginlik" },
      { label: "Dilde Gerginlik", value: "dilde_gerginlik" },
      { label: "Glottal Fry", value: "glottal_fry" },
      {
        label: "Aşırı Ekstrinsik Larengeal Kas Kullanımı",
        value: "asiri_ekstrinsik_larengeal_kas_kullanımı",
      },
      { label: "Diplofoni", value: "diplofoni" },
    ],
  },
  {
    name: "boguk_ses",
    label: "Boğuk Ses",
    type: "radio",
    options: [
      { value: "hafif", label: "Hafif" },
      { value: "orta", label: "Orta" },
      { value: "ileri", label: "İleri" },
      { value: "yok", label: "Yok" },
    ],
  },
  {
    name: "nefesli_ses",
    label: "Nefesli Ses",
    type: "radio",
    options: [
      { value: "hafif", label: "Hafif" },
      { value: "orta", label: "Orta" },
      { value: "ileri", label: "İleri" },
      { value: "yok", label: "Yok" },
    ],
  },
  {
    name: "hisirtili_ses",
    label: "Hışırtılı Ses",
    type: "radio",
    options: [
      { value: "hafif", label: "Hafif" },
      { value: "orta", label: "Orta" },
      { value: "ileri", label: "İleri" },
      { value: "yok", label: "Yok" },
    ],
  },
  {
    name: "tini",
    label: "Tını",
    type: "radio",
    options: [
      { label: "Yeterli", value: "yeterli" },
      { label: "Yüksek", value: "yuksek" },
      { label: "Alçak", value: "alcak" },
    ],
  },
  {
    name: "tini_ranji",
    label: "Tını Ranjı",
    type: "radio",
    options: [
      { label: "Yeterli", value: "yeterli" },
      { label: "Sınırlı", value: "sinirli" },
      { label: "Üst / Alçak", value: "ust_ve_alcak" },
    ],
  },
  {
    name: "grade",
    label: "GRADE",
    type: "radio",
    options: [
      { label: "0=Normal", value: 0 },
      { label: "1=Hafif etkilenmiş", value: 1 },
      { label: "2=Orta derecede etkilenmiş", value: 2 },
      { label: "3=Şiddetli derecede etkilenmiş", value: 3 },
    ],
  },
  {
    name: "roughness",
    label: "ROUGHNESS",
    type: "radio",
    options: [
      { label: "0=Normal", value: 0 },
      { label: "1=Hafif etkilenmiş", value: 1 },
      { label: "2=Orta derecede etkilenmiş", value: 2 },
      { label: "3=Şiddetli derecede etkilenmiş", value: 3 },
    ],
  },
  {
    name: "breathiness",
    label: "BREATHINESS",
    type: "radio",
    options: [
      { label: "0=Normal", value: 0 },
      { label: "1=Hafif etkilenmiş", value: 1 },
      { label: "2=Orta derecede etkilenmiş", value: 2 },
      { label: "3=Şiddetli derecede etkilenmiş", value: 3 },
    ],
  },
  {
    name: "asthenia",
    label: "ASTHENIA",
    type: "radio",
    options: [
      { label: "0=Normal", value: 0 },
      { label: "1=Hafif etkilenmiş", value: 1 },
      { label: "2=Orta derecede etkilenmiş", value: 2 },
      { label: "3=Şiddetli derecede etkilenmiş", value: 3 },
    ],
  },
  {
    name: "strain",
    label: "STRAIN",
    type: "radio",
    options: [
      { label: "0=Normal", value: 0 },
      { label: "1=Hafif etkilenmiş", value: 1 },
      { label: "2=Orta derecede etkilenmiş", value: 2 },
      { label: "3=Şiddetli derecede etkilenmiş", value: 3 },
    ],
  },
  {
    name: "max_phonation_time_a",
    label: "Maksimum Fonasyon Süresi /a/ sn.",
    type: "text",
  },
  {
    name: "max_phonation_time_i",
    label: "Maksimum Fonasyon Süresi /i/ sn.",
    type: "text",
  },
  { name: "max_s_duration", label: "Maksimum /s/ Süresi sn.", type: "text" },
  { name: "max_z_duration", label: "Maksimum /z/ Süresi sn.", type: "text" },
  { name: "s_z_ratio", label: "s/z Oranı Ort.", type: "text" },
  {
    label: "Algısal Analiz",
    name: "algisal_analiz",
    type: "checkbox",
    options: [
      { value: "facial_tension", label: "Yüzde Gerginlik" },
      { value: "neck_tension", label: "Boyun Gerginliği" },
      { value: "inappropriate_timbre", label: "Uygun Olmayan Tını" },
      { value: "poor_breath_control", label: "Zayıf Solunum Kontrolü" },
      { value: "breathy_voice", label: "Nefesli Ses" },
      { value: "low_pitch_voice_loss", label: "Alçak Tında Ses Kaybı" },
      { value: "high_pitch_voice_loss", label: "Yüksek Tında Ses Kaybı" },
    ],
  },
  { name: "f0_hz", label: "F0 ...Hz", type: "text" },
  { name: "jitter", label: "Jitter", type: "text" },
  { name: "shimmer", label: "Shimmer", type: "text" },
  { name: "signal_noise_ratio", label: "Sinyal Gürültü Oranı", type: "text" },
  {
    name: "voice_handicap_index_score",
    label: "Ses Handikap İndeks Puanı",
    type: "text",
  },
  {
    name: "glottal_closure_type",
    label: "Glottal Kapanma Türü",
    type: "radio",
    options: [
      { value: "komplet", label: "Komplet" },
      { value: "anterior", label: "Anterior" },
      { value: "posterior", label: "Posterior" },
      { value: "inkomplet", label: "İnkomplet" },
      { value: "duzensiz", label: "Düzensiz" },
      { value: "ortada", label: "Ortada" },
      { value: "kumsaati", label: "Kum Saati" },
    ],
  },
  {
    name: "mucosal_wave_presence",
    label: "Mukozal Dalga Varlığı",
    type: "radio",
    options: [
      { value: 0, label: "Normal" },
      { value: 1, label: "Hafif derece azalmış" },
      { value: 2, label: "Orta derece azalmış" },
      { value: 3, label: "Şiddetli derece azalmış" },
      { value: 4, label: "Görülebilen dalga varlığı yok" },
    ],
  },
  {
    name: "periodicity",
    label: "Periodisite",
    type: "radio",
    options: [
      { value: 0, label: "Düzensiz" },
      { value: 1, label: "Bazen düzenli" },
      { value: 2, label: "Çoğunlukla düzenli" },
      { value: 3, label: "Her zaman düzenli" },
    ],
  },
  {
    name: "amplitude",
    label: "Amplitüd",
    type: "radio",
    options: [
      { value: 0, label: "Normal" },
      { value: 1, label: "Hafif azalmış" },
      { value: 2, label: "Orta derecede azalmış" },
      { value: 3, label: "Görünebilir hareket yok" },
    ],
  },
  {
    label: "Hamilelik Süresi",
    name: "hamilelik_suresi",
    type: "text",
  },
  {
    label: "Doğum Süresi",
    name: "dogum_suresi",
    type: "text",
  },
  {
    label: "Hamilelik Sırasında...",
    name: "hamilelik_sirasinda",
    type: "textarea",
  },
  {
    label: "Doğum Sırasında Anne İlaç Aldı mı?",
    name: "ilac_alma",
    options: [
      {
        label: "Evet",
        value: "evet",
      },
      {
        label: "Hayır",
        value: "hayır",
      },
    ],
    type: "radio",
  },
  {
    label: "Annenin Hamilelik Sürecindeki Durumu Nasıldı ?",
    name: "anne_durumu",
    options: [
      {
        label: "İyi",
        value: "iyi",
      },
      {
        label: "Fena Değil",
        value: "fenaDegil",
      },
      {
        label: "Kötü",
        value: "kotu",
      },
    ],
    type: "radio",
  },
  {
    label: "Doğum Sırasında Ortaya Çıkan Güçlükler",
    name: "dogum_guclukler",
    type: "textarea",
  },
  {
    label: "Bebek Doğum Sırasında Oksijensiz Kaldı mı ?",
    name: "bebek_oksijensiz_kalma",
    options: [
      {
        label: "Evet",
        value: "evet",
      },
      {
        label: "Hayır",
        value: "hayır",
      },
    ],
    type: "radio",
  },
  {
    label: "Varsa Diğer Zarar Verici Durumları Tanımlar mısınız ?",
    name: "diger_zarar_verici_durumlar",
    type: "textarea",
  },
  {
    label: "Doğum Sonrası Ortaya Çıkan Güçlükler",
    name: "dogum_sonrasi_guclukler",
    type: "textarea",
  },
  {
    label: "Akraba Evliliği Var mı ?",
    name: "akraba_evliligi",
    options: [
      {
        label: "Var",
        value: "var",
      },
      {
        label: "Yok",
        value: "yok",
      },
    ],
    type: "radio",
  },
  {
    label: "Vaka Öyküsü",
    name: "vaka_oykusu",
    type: "textarea",
  },
  {
    label: "Ailede Konuşma Sorunu Olan Var mı ?",
    name: "ailede_konusma_sorunu_olan_var_mi",
    options: [
      {
        label: "Var",
        value: "var",
      },
      {
        label: "Yok",
        value: "yok",
      },
    ],
    type: "radio",
  },
  {
    label: "Muhtemel konuşmaya başlama yaşı",
    name: "muhtemel_konusmaya_baslama_yasi",
    type: "text",
  },
  {
    label: "Çocuğunuz Beslenme Güçlüğü (emme,yutma vs.) Çekti mi ?",
    name: "beslenme_guclugu_cekme",
    options: [
      {
        label: "Çekti",
        value: "cekti",
      },
      {
        label: "Çekmedi",
        value: "cekmedi",
      },
    ],
    type: "radio",
  },
  {
    label: "Varsa çocuğunuzun diğer geçirdiği çocukluğu çağı hastalıkları",
    name: "varsa_gecirilen_cocukluk_hastaliklar",
    type: "textarea",
  },
  {
    label: "Çocuğunuz orta kulak enfeksiyonu geçirdi mi ?",
    name: "orta_kulak_enfeksiyonu_gecirdi_mi",
    options: [
      {
        label: "Geçirdi",
        value: "gecirdi",
      },
      {
        label: "Geçirmedi",
        value: "gecirmedi",
      },
    ],
    type: "radio",
  },
  {
    label: "Çocuğunuzun herhangi bir işitme sorunu var mı ?",
    name: "isitme_sorunu",
    options: [
      {
        label: "Var",
        value: "var",
      },
      {
        label: "Yok",
        value: "yok",
      },
    ],
    type: "radio",
  },
  {
    label: "Çocuğunuz daha önce hiçbir ameliyat geçirdi mi ?",
    name: "ameliyat_gecirme",
    options: [
      {
        label: "Geçirdi",
        value: "gecirdi",
      },
      {
        label: "Geçirmedi",
        value: "gecirmedi",
      },
    ],
    type: "radio",
  },
  {
    label: "Varsa geçirdiği ameliyatlar ve tarihi",
    name: "gecirilen_ameliyatlar_ve_tarihi",
    type: "textarea",
  },
  {
    label: "Çocuğunuzun dil ve konuşma problemini tanımlayınız",
    name: "dil_ve_konusma_problemi_tanimi",
    type: "textarea",
  },
  {
    label: "Çocuğunuz hangi elini kullanır ?",
    name: "hangi_elini_kullanir",
    options: [
      {
        label: "Sağ",
        value: "sag",
      },
      {
        label: "Sol",
        value: "sol",
      },
    ],
    type: "radio",
  },
  {
    label: "Çocuğunuzla gün boyu kim ilgileniyor ?",
    name: "kim_ilgileniyor",
    type: "text",
  },
  {
    label: "Çocuğunuz okula gidiyorsa okuldaki durumu hakkında bilgi veriniz",
    name: "okuldaki_durumu",
    type: "textarea",
  },
  {
    label: "Emekleme (kaba motor becerileri)",
    name: "emekleme",
    options: [
      {
        label: "Yardımlı",
        value: "yardimli",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label: "Ayakta durma (kaba motor becerileri)",
    name: "ayakta_durma",
    options: [
      {
        label: "Yardımlı",
        value: "yardimli",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label: "Otururken ayağa kalkma (kaba motor becerileri)",
    name: "otururken_ayaga_kalkma",
    options: [
      {
        label: "Yardımlı",
        value: "yardimli",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label: "Merdivenlerden inip çıkma (kaba motor becerileri)",
    name: "merdivenlerden_inip_cikma",
    options: [
      {
        label: "Yardımlı",
        value: "yardimli",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label: "Zıplama (kaba motor becerileri)",
    name: "ziplama",
    options: [
      {
        label: "Yardımlı",
        value: "yardimli",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label: "Koşma (kaba motor becerileri)",
    name: "kosma",
    options: [
      {
        label: "Yardımlı",
        value: "yardimli",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label: "Bisiklet sürme (kaba motor becerileri)",
    name: "bisiklet_surme",
    options: [
      {
        label: "Yardımlı",
        value: "yardimli",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label: "Topa tekme vurma (kaba motor becerileri)",
    name: "topa_tekme_vurma",
    options: [
      {
        label: "Yardımlı",
        value: "yardimli",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label:
      "Çocuğunuz aşağıdaki ince motor becerilerden hangilerini gerçekleştirebilmektedir",
    name: "ince_motor_becerileri",
    options: [
      {
        label: "Eline verilen oyuncağı tutma",
        value: "0",
      },
      {
        label: "Kağıdı karalama",
        value: "1",
      },
      {
        label: "Oyuncağı bir elinden diğerine geçirme",
        value: "2",
      },
      {
        label: "Kitabın sayfalarını çevirme",
        value: "3",
      },
      {
        label: "Küçük bir nesneyi başparmak ve diğerleriyle tutma",
        value: "4",
      },
      {
        label: "Makasla gelişigüzel kesme",
        value: "5",
      },
      {
        label: "Oyuncak arabayı itme",
        value: "6",
      },
      {
        label: "Basit şekilleri çizerek taklit etme",
        value: "7",
      },
      {
        label: "İki nesneyi birbirine vurma",
        value: "8",
      },
    ],
    type: "checkbox",
  },
  {
    label: "Çocuğunuz çişini kendisi yapabilir mi (tuvalet becerileri)",
    name: "cisi_kendisi_yapabilir",
    options: [
      {
        label: "Yapabilir",
        value: "yapabilir",
      },
      {
        label: "Yapamaz",
        value: "yapamaz",
      },
    ],
    type: "radio",
  },
  {
    label: "Çocuğunuz kakasını kendi yapabilir mi (tuvalet becerileri)",
    name: "kakasini_kendi_yapabilir",
    options: [
      {
        label: "Yapabilir",
        value: "yapabilir",
      },
      {
        label: "Yapamaz",
        value: "yapamaz",
      },
    ],
    type: "radio",
  },
  {
    label:
      "Çocuğunuz tuvalet temizliğini kendisi yapabilir mi (tuvalet becerileri)",
    name: "tuvalet_temizligi_kendi_yapabilir",
    options: [
      {
        label: "Yapabilir",
        value: "yapabilir",
      },
      {
        label: "Yapamaz",
        value: "yapamaz",
      },
    ],
    type: "radio",
  },
  {
    label:
      "Çocuğunuz tuvaletinin geldiğini nasıl ifade ediyor (tuvalet becerileri)",
    name: "tuvaletinin_geldigini_nasil_ifade_ediyor",
    type: "textarea",
  },
  {
    label: "Çocuğunuz giyinme becerilerine ne ölçüde sahiptir",
    name: "giyinme_becerileri",
    options: [
      {
        label: "Kazağını Giyip Çıkarır",
        value: "0",
      },
      {
        label: "Pantolonunu Giyip Çıkarır",
        value: "1",
      },
      {
        label: "Çorabını Giyip Çıkarır",
        value: "2",
      },
      {
        label: "Ayakkabılarını Giyer Çıkarır",
        value: "3",
      },
    ],
    type: "checkbox",
  },
  {
    label: "Yukarıda seçtiğiniz becerilerin hangilerini yardımla yapabilir",
    name: "giyinme_becerileri_yardimla",
    type: "textarea",
  },
  {
    label: "Çocuğunuz hangi yemek yeme becerilerine sahiptir",
    name: "yemek_yeme_becerileri",
    options: [
      {
        label: "Kaşıkla Yemek Yer",
        value: "0",
      },
      {
        label: "Çatal Kullanır",
        value: "1",
      },
      {
        label: "Bıçak Kullanır",
        value: "2",
      },
      {
        label: "Bardaktan Su İçer",
        value: "3",
      },
    ],
    type: "checkbox",
  },
  {
    label:
      "Çocuğunuz yukarıdaki seçtiğiniz yeme becerilerinden hangilerini yardımla yapabilir",
    name: "yemek_yeme_becerileri_yardimla",
    type: "textarea",
  },
  {
    label:
      "Çocuğunuz renk, şekil, sayı, büyüklük kavramlarıyla ilgili olarak hangi becerilere sahiptir",
    name: "renk_sekil_sayi_buyukluk_kavramlari",
    options: [
      {
        label: "Renklerin isimlerini bilir ve yerinde kullanır",
        value: "0",
      },
      {
        label: "Söylenen sayı kadar objeyi getirir",
        value: "1",
      },
      {
        label: "Aynı şekilde objeleri birleştirir",
        value: "2",
      },
      {
        label: "Büyük-küçük sözcüklerini bilir ve yerinde kullanır",
        value: "3",
      },
    ],
    type: "checkbox",
  },
  {
    label:
      "Çocuğunuzda belirgin alışkanlık haline gelmiş davranışlar var mıdır",
    name: "belirgin_aliskanliklar",
    options: [
      {
        label: "Var",
        value: "var",
      },
      {
        label: "Yok",
        value: "yok",
      },
    ],
    type: "radio",
  },
  {
    label: "Varsa nelerdir",
    name: "belirgin_aliskanliklar_detay",
    type: "textarea",
  },
  {
    label: "Sese tepkide bulunma",
    name: "sese_tepkide_bulunma",
    options: [
      {
        label: "Yardımlı",
        value: "yardimsiz",
      },
      {
        label: "Yardımsız",
        value: "yardimsiz",
      },
    ],
    type: "radio",
  },
  {
    label:
      "Çocuğunuz dil gelişimi ile ilgili olarak aşağıdakilerden hangilerini gerçekleştirmektedir",
    name: "dil_gelisimi",
    options: [
      {
        label: "Sese tepkide bulunma",
        value: "0",
      },
      {
        label: "Konuşanın gözlerini ve ağzını izleme",
        value: "1",
      },
      {
        label:
          "Ses tonundaki farklılıklara uygun tepki verme (kızgın-neşeli vb.)",
        value: "2",
      },
      {
        label: "Adı söylenen nesneyi seçme",
        value: "3",
      },
      {
        label: "Tek sözcüklü yönergeleri anlama-yerine getirme",
        value: "4",
      },
      {
        label: "İki sözcüklü yönergeleri anlama-yerine getirme",
        value: "5",
      },
      {
        label: "Çok sözcüklü yönergeleri anlama-yerine getirme",
        value: "6",
      },
      {
        label: "Kısa bir öyküyü sonuna kadar dinleme",
        value: "7",
      },
      {
        label: "Çoğulları ayırt etme",
        value: "8",
      },
      {
        label: "İlgi adıllarını ayırt etme (benim, senin vs.)",
        value: "9",
      },
      {
        label:
          "Yer bildiren sözcüklere (içinde, altında, üstünde vb.) uygun tepki",
        value: "10",
      },
    ],
    type: "checkbox",
  },
  {
    label: "Basit soruları yanıtlama",
    name: "basit_sorulari_yanitlama",
    options: [
      {
        label: "Tek Sözcükle",
        value: "tek",
      },
      {
        label: "İki Sözcükle",
        value: "iki",
      },
      {
        label: "Çok Sözcükle",
        value: "cok",
      },
    ],
    type: "radio",
  },
  {
    label: "Çocuğunuz nasıl iletişim kurar",
    name: "iletisim_kurmak",
    type: "textarea",
  },
  {
    label: "Yardım ister mi",
    name: "yardim_ister",
    options: [
      {
        label: "Yardım ister",
        value: "yardimister",
      },
      {
        label: "Yardım istemez",
        value: "yardimistemez",
      },
    ],
    type: "radio",
  },
  {
    label: "Bir nesneyi işaret eder mi",
    name: "nesneyi_isaret_eder",
    options: [
      {
        label: "Eder",
        value: "eder",
      },
      {
        label: "Etmez",
        value: "etmez",
      },
    ],
    type: "radio",
  },
  {
    label: "İlgi bekler mi",
    name: "ilgi_bekler",
    options: [
      {
        label: "Bekler",
        value: "bekler",
      },
      {
        label: "Beklemez",
        value: "beklemez",
      },
    ],
    type: "radio",
  },
  {
    label: "Hoşlanmadığı şeyleri reddedip protesto eder mi",
    name: "hoslanmadigi_seyleri_reddeder",
    options: [
      {
        label: "Eder",
        value: "eder",
      },
      {
        label: "Etmez",
        value: "etmez",
      },
    ],
    type: "radio",
  },
  {
    label: "İletişimi kendisi başlatır mı",
    name: "iletisimi_baslatir",
    options: [
      {
        label: "Başlatır",
        value: "baslatir",
      },
      {
        label: "Başlatmaz",
        value: "baslatmaz",
      },
    ],
    type: "radio",
  },
  {
    label: "Sıra alma davranışı sergiler mi",
    name: "sira_alma_davranisi",
    options: [
      {
        label: "Sergiler",
        value: "sergiler",
      },
      {
        label: "Sergilemez",
        value: "sergilemez",
      },
    ],
    type: "radio",
  },
  {
    label: "Hangi durumlarda iletişimi kendisi başlatır",
    name: "itelisim_kendisi_baslatir",
    type: "textarea",
  },
  {
    label: "Yalnız mı yoksa başkalarıyla oynamayı mı tercih eder",
    name: "oynamayi_tercih",
    options: [
      {
        label: "Yalnız",
        value: "yalniz",
      },
      {
        label: "Başkalarıyla",
        value: "baskalariyla",
      },
    ],
    type: "radio",
  },
];

const formTranslations = fields.reduce((acc, field) => {
  acc[field.name] = {
    OriginalQuestion: field.label,
    OriginalAnswer: "", // Fill this with the original answer if available
    [`${field.name}_Translation`]: "", // Placeholder for translated text
  };

  if (field.options) {
    acc[field.name].Options = field.options.map((option) => ({
      OriginalLabel: option.label,
      TranslatedLabel: "", // Placeholder for translated label
    }));
  }

  return acc;
}, {});

export default formTranslations;
