import React, { useState, useEffect } from "react";
import Link from "../../components/linkWithLang";
import Swal from "sweetalert2";
import { TextInput } from "../../../../components/inputs/Index";
import { useLanguage } from "../../context/Translator";
import { languageService } from "../../functions/index";
import DoctorLayout from "../../../../components/layout/doctor/sidebar/Index";
import Modal from "../../../../components/layout/modal/Index";
import { useAuth } from "../../../../context/AuthContext";

export default function Languages() {
  const { userData } = useAuth();
  const { translate } = useLanguage();
  const [languages, setLanguages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    ISOCODE: "",
    isUsable: true,
  });

  const getLanguages = async () => {
    const languages = await languageService.getLanguages();
    setLanguages(languages);
  };

  useEffect(() => {
    getLanguages();
  }, []);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleSubmit = async () => {
    if (formValues.name !== "" && formValues.ISOCODE !== "") {
      const response = await languageService.createLanguage(formValues);
      getLanguages();
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Başarılı",
          text: "Dil başarıyla eklendi",
        });
        setModalOpen(false);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Başarısız",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleDeactivate = async (id) => {
    const response = await languageService.disableLanguage(id);

    if (response) {
      getLanguages();
      Swal.fire({
        icon: "success",
        title: "Başarılı",
        text: "Dil başarıyla yayından alındı",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Başarısız",
      });
    }
  };

  return (
    <DoctorLayout>
      <div className="px-4 sm:px-6 lg:px-8 py-6 rounded-md">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-gray-900">
              {translate("Diller")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {translate(
                "A list of all the users in your account including their name, title, email and role."
              )}
            </p>
          </div>

          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={toggleModal}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {translate("Dil Ekle")}
            </button>
          </div>
        </div>
        {/* <div className="my-4 flex items-center gap-6">
          <button className="bg-gray-300 px-4 py-1 rounded-xl" type="button">
            All ()
          </button>
          <button className="bg-gray-300 px-4 py-1 rounded-xl" type="button">
            On Production ()
          </button>
          <button className="bg-gray-300 px-4 py-1 rounded-xl" type="button">
            On Archieve ()
          </button>
        </div> */}
        <div className="-mx-4 mt-8 sm:-mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-teal-600 ">
              <tr className="">
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-3 rounded-t-lg rounded-r-none"
                >
                  {translate("Ad")}
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-white lg:table-cell "
                >
                  ISOCODE
                </th>
                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 sm:pr-0 rounded-t-lg rounded-l-none"
                >
                  <span className="sr-only">{translate("Düzenle")}</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {languages.map((lang) => (
                <tr key={lang.ISOCODE}>
                  <td className="w-full max-w-0 py-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-3">
                    <Link
                      className="underline-offset-4 underline"
                      to={`/languages/${lang.ISOCODE}`}
                    >
                      {lang.name}
                    </Link>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell uppercase">
                    {lang.ISOCODE}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <Link
                      to={`/languages/${lang.ISOCODE}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      {translate("Düzenle")}
                    </Link>
                    <button
                      type="button"
                      onClick={() => {
                        handleDeactivate(lang.id);
                      }}
                      className="text-red-600 hover:text-red-900 mx-3"
                    >
                      {translate("Yayından Al")}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal open={modalOpen} setOpen={setModalOpen}>
        <Modal.Title>{translate("Dil Ekle")}</Modal.Title>
        <Modal.Content>
          <div className="mt-2 text-sm text-gray-500 flex flex-col gap-3">
            <TextInput
              name="name"
              label={translate("Dil Adı")}
              value={formValues.name}
              onChange={handleChange}
            />
            <TextInput
              name="ISOCODE"
              label={translate("ISOCODE")}
              value={formValues.ISOCODE}
              onChange={handleChange}
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <button
            onClick={handleSubmit}
            className="w-full bg-indigo-500 text-white p-1 rounded-md"
          >
            Ekle
          </button>
        </Modal.Footer>
      </Modal>
    </DoctorLayout>
  );
}
