import { useLanguage } from "../../services/multi-lang/context/Translator";
import Link from "../../services/multi-lang/components/linkWithLang";
const placeholderUserIcon = "/placeholder.jpeg";

export default function Card({ patient, to }) {
  const { translate } = useLanguage();
  return (
    <li className="overflow-hidden rounded-xl border border-gray-300 shadow-md mt-2">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <Link className="underline underline-offset-4" to={to ?? "#"}>
          <img
            src={patient?.photoUrl ?? placeholderUserIcon}
            className="size-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
          />
          <div className="text-sm/6 font-medium text-gray-900">
            {patient?.firstName ?? patient?.patient?.firstName}{" "}
            {patient?.lastName ?? patient?.patient?.lastName ?? ""}
            {!patient?.firstName &&
              !patient?.patient?.firstName &&
              !patient?.lastName &&
              !patient?.patient?.lastName &&
              "Bilgiye Ulaşılamadı"}
          </div>
        </Link>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">{translate("Telefon")}</dt>
          <dd className="text-gray-700">
            {patient?.phone ?? patient?.patient?.phone ?? "Bilgiye Ulaşılamadı"}
          </dd>
        </div>
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">
            {translate("Bakım Veren Adı Soyadı")}
          </dt>
          <dd className="flex items-start gap-x-2">
            <div className="font-medium text-gray-900">
              {patient?.caregiverName ??
                patient?.patient?.caregiverName ??
                "Bilgiye Ulaşılamadı"}
            </div>
          </dd>
        </div>
      </dl>
    </li>
  );
}
