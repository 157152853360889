import React, { useState, useEffect } from "react";
import { languageService } from "../functions";
import { SelectInput, SelectOption } from "../../../components/inputs/Index";
import { useLanguage } from "../context/Translator";
import { useNavigate } from "react-router-dom";

const trFlag = "/tr.svg";
const enFlag = "/gb.svg";

export default function LanguagePicker() {
  const [langs, setLangs] = useState([]);
  const { currentLanguage, setCurrentLanguage } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const response = await languageService.getLanguages();

      setLangs(response);
    };

    getData();
  }, []);

  const handleNavigate = (lang) => {
    setCurrentLanguage(lang);
    const newPath = window.location.pathname.replace(/^\/[^/]+/, `/${lang}`);

    navigate(newPath);
  };

  return (
    <div className="flex items-center gap-3">
      <button
        className="p-0.5 bg-gray-100 rounded-full"
        onClick={() => {
          handleNavigate("tr");
        }}
      >
        <img
          className="w-5 h5 rounded-full"
          src={trFlag}
          alt="Turkiye Flag to Pick Language"
        />
      </button>
      <button
        className="p-0.5 bg-gray-100 rounded-full"
        onClick={() => {
          handleNavigate("en");
        }}
      >
        <img
          className="w-5 h-5 rounded-full"
          src={enFlag}
          alt="English Flag to Pick Language"
        />
      </button>
    </div>
  );
}
