import React from "react";
import {
  TextInput,
  SelectOption,
  SelectInput,
  RadioGroup,
  RadioOption,
  CheckboxGroup,
  CheckboxOption,
  TextareaInput,
  DateInput,
} from "../../../components/inputs/Index";
import { useLanguage } from "../../../services/multi-lang/context/Translator";

export default function Form({ formik }) {
  const { translate } = useLanguage();
  const fields = [
    {
      label: translate("hamilelik_suresi_Question"),
      name: "hamilelik_suresi",
      type: "text",
    },
    {
      label: translate("dogum_suresi_Question"),
      name: "dogum_suresi",
      type: "text",
    },
    {
      label: translate("hamilelik_sirasinda_Question"),
      name: "hamilelik_sirasinda",
      type: "textarea",
    },
    {
      label: translate("ilac_alma_Translation"),
      name: "ilac_alma",
      options: [
        {
          label: translate("ilac_alma_Option_0"),
          value: "evet",
        },
        {
          label: translate("ilac_alma_Option_1"),
          value: "hayır",
        },
      ],
      type: "radio",
    },
    {
      label: "Annenin Hamilelik Sürecindeki Durumu Nasıldı ?",
      name: "anne_durumu",
      options: [
        {
          label: "İyi",
          value: "iyi",
        },
        {
          label: "Fena Değil",
          value: "fenaDegil",
        },
        {
          label: "Kötü",
          value: "kotu",
        },
      ],
      type: "radio",
    },
    {
      label: "Doğum Sırasında Ortaya Çıkan Güçlükler",
      name: "dogum_guclukler",
      type: "textarea",
    },
    {
      label: "Bebek Doğum Sırasında Oksijensiz Kaldı mı ?",
      name: "bebek_oksijensiz_kalma",
      options: [
        {
          label: "Evet",
          value: "evet",
        },
        {
          label: "Hayır",
          value: "hayır",
        },
      ],
      type: "radio",
    },
    {
      label: "Varsa Diğer Zarar Verici Durumları Tanımlar mısınız ?",
      name: "diger_zarar_verici_durumlar",
      type: "textarea",
    },
    {
      label: "Doğum Sonrası Ortaya Çıkan Güçlükler",
      name: "dogum_sonrasi_guclukler",
      type: "textarea",
    },
    {
      label: "Akraba Evliliği Var mı ?",
      name: "akraba_evliligi",
      options: [
        {
          label: "Var",
          value: "var",
        },
        {
          label: "Yok",
          value: "yok",
        },
      ],
      type: "radio",
    },
  ];

  return (
    <>
      {fields.map((field, index) => {
        switch (field.type) {
          case "text":
            return (
              <TextInput
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                label={translate(`${field.name}_Question`)}
                name={field.name}
                key={index}
              />
            );
          case "select":
            return (
              <SelectInput
                label={translate(`${field.name}_Translation`)}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              >
                {field.options.map((option, index) => (
                  <SelectOption key={index} value={option.value}>
                    {option.label}
                  </SelectOption>
                ))}
              </SelectInput>
            );
          case "date":
            return <DateInput />;

          case "radio":
            return (
              <RadioGroup
                label={translate(`${field.name}_Translation`)}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              >
                {field.options.map((option, index) => (
                  <RadioOption key={index + option.value} value={option.value}>
                    {option.label}
                  </RadioOption>
                ))}
              </RadioGroup>
            );

          case "checkbox":
            return (
              <CheckboxGroup
                label={translate(`${field.name}_Translation`)}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              >
                {field.options.map((option, index) => (
                  <CheckboxOption key={index} value={option.value}>
                    {option.label}
                  </CheckboxOption>
                ))}
              </CheckboxGroup>
            );

          case "textarea":
            return (
              <TextareaInput
                name={field.name}
                key={index}
                label={translate(`${field.name}_Question`)}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              />
            );

          default:
            return null;
        }
      })}
    </>
  );
}
