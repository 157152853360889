import React from "react";

const Logo = "/logo2.png";

const Loading = () => {
  return (
    <div className="flex flex-col bg-teal-600 items-center justify-center h-screen">
      <img
        className="w-auto animate-pulse duration-600 h-32 sm:h-24"
        src={Logo}
        alt="Logo"
      />
    </div>
  );
};

export default Loading;
