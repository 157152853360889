import React, { useState, useEffect, useCallback } from "react";
import { TextareaInput, TextInput } from "../../../../components/inputs/Index";
import DoctorLayout from "../../../../components/layout/doctor/sidebar/Index";
import { useParams } from "react-router-dom";
import {
  languageService,
  pricingHeaders,
  emptyValues,
  emptyParagraphValues,
  emptyExerciseValues,
  checkoutPageValues,
  emptyHeroValues,
  emptyPricingValues,
} from "../../functions";
import { debounce } from "lodash";
import formTranslations from "../../../../forms/ses-form/master";
import { Tab } from "@headlessui/react";

const Item = ({ label, name, value, onChange, status }) => {
  return (
    <li
      key={name + label}
      className="overflow-hidden rounded-md bg-white p-6 shadow"
    >
      <TextareaInput
        className="resize-none"
        rows={3}
        cols={33}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
      />
      {status && (
        <p
          className={`mt-2 text-sm ${
            status === "updating"
              ? "text-blue-500"
              : status === "done"
              ? "text-green-500"
              : "text-red-500"
          }`}
        >
          {status === "updating"
            ? "Updating..."
            : status === "done"
            ? "Updated successfully!"
            : "Update failed!"}
        </p>
      )}
    </li>
  );
};

const ParagraphItem = ({
  updateValue,
  mainKey,
  dictionary,
  status,
  ...props
}) => {
  const handleChange = (e) => {
    updateValue(e.target.name, e.target.value);
  };

  return (
    <li
      key={mainKey}
      className="overflow-hidden rounded-md bg-white p-6 gap-3 shadow grid-cols-2 sm:grid-cols-5 grid items-center"
    >
      <div className="col-span-2">
        <p className="text-sm font-semibold">
          {props["OriginalQuestion"] || props["Original_Title"]}
        </p>
        <p className="text-xs">
          {props["OriginalAnswer"] || props["Original_Paragraph"]}
        </p>
      </div>
      <div className="col-span-3">
        <TextInput
          name={mainKey + "_Title"}
          value={dictionary[mainKey + "_Title"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        <hr />
        <TextareaInput
          className="resize-none"
          rows={3}
          cols={33}
          name={mainKey + "_Paragraph"}
          value={dictionary[mainKey + "_Paragraph"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        {status && (
          <p
            className={`mt-2 text-sm ${
              status === "updating"
                ? "text-blue-500"
                : status === "done"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {status === "updating"
              ? "Updating..."
              : status === "done"
              ? "Updated successfully!"
              : "Update failed!"}
          </p>
        )}
      </div>
    </li>
  );
};

const ExerciseItem = ({
  updateValue,
  mainKey,
  dictionary,
  status,
  ...props
}) => {
  const handleChange = (e) => {
    updateValue(e.target.name, e.target.value);
  };

  return (
    <li
      key={mainKey}
      className="overflow-hidden rounded-md bg-white p-6 gap-3 shadow grid-cols-2 sm:grid-cols-5 grid items-center"
    >
      <div className="col-span-2">
        <p className="text-sm font-semibold">
          {props["OriginalTitle"] || props["Original_Title"]}
        </p>
        <p className="text-xs font-semibold">{props["OriginalSubtitle"]}</p>
        <p className="text-xs">
          {props["OriginalParagraph"] || props["Original_Paragraph"]}
        </p>
      </div>
      <div className="col-span-3">
        <TextInput
          name={mainKey + "_Title"}
          value={dictionary[mainKey + "_Title"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        <hr />
        <TextInput
          name={mainKey + "_Subtitle"}
          value={dictionary[mainKey + "_Subtitle"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        <hr />
        <TextareaInput
          className="resize-none"
          rows={3}
          cols={33}
          name={mainKey + "_Paragraph"}
          value={dictionary[mainKey + "_Paragraph"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        {status && (
          <p
            className={`mt-2 text-sm ${
              status === "updating"
                ? "text-blue-500"
                : status === "done"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {status === "updating"
              ? "Updating..."
              : status === "done"
              ? "Updated successfully!"
              : "Update failed!"}
          </p>
        )}
      </div>
    </li>
  );
};

const HeroItems = ({ dictionary, setDictionary, ...props }) => {
  const { isocode } = useParams();

  const debouncedUpdateDictionaryValue = useCallback(
    debounce(async (key, value) => {
      const response = await languageService.updateDictionaryValue(
        isocode,
        key,
        value
      );

      console.log("Update response", response);
    }, 300),
    [isocode]
  );

  const updateValue = (e) => {
    const { name, value } = e.target;
    const key = name;

    // Update the local dictionary state
    setDictionary((prev) => ({ ...prev, [key]: value }));
    // Call the debounced server update
    debouncedUpdateDictionaryValue(key, value);
  };

  return (
    <li className="overflow-hidden rounded-md bg-white p-6 gap-3 shadow grid-cols-2 sm:grid-cols-5 grid items-center">
      <div className="col-span-2">
        <p className="text-sm font-semibold">
          Crocodil terapi kayıt ve takip programı
        </p>
        <p className="text-sm font-semibold">
          Terapi masada, <span className="text-teal-500">takip dijitalde.</span>
        </p>
        <p className="text-xs">
          Sağlık alanında çalışan her meslek dalında olduğu gibi Dil ve Konuşma
          Terapistlerinin de danışanlarının sağlık verilerini, terapi
          oturumlarını ve ödevlendirmelerini kayıt altına almasının önemi her
          geçen gün artmaktadır. Bu verilerin kaydının daha düzenli yapılması,
          yeri geldiğinde verilere ulaşımın kolay olması, danışan ve ailelerine
          geri dönüt ve nesnel veri sağlanmasında kolaylık sağlaması noktasından
          Crocodil terapi kayıt ve takip sistemi büyük bir boşluğu
          doldurmaktadır.
        </p>
      </div>
      <div className="col-span-3">
        <TextInput
          name="HERO_Title_1"
          value={dictionary["HERO_Title_1"]}
          onChange={updateValue}
          label="Çevirisi"
        />
        <hr />
        <TextInput
          name="HERO_Title_2"
          value={dictionary["HERO_Title_2"]}
          onChange={updateValue}
          label="Terapi Masada Çevirisi"
        />
        <hr />
        <TextInput
          name="HERO_Title_1_Bold"
          value={dictionary["HERO_Title_1_Bold"]}
          onChange={updateValue}
          label="Yeşil Yazı Çevirisi"
        />
        <hr />
        <TextareaInput
          className="resize-none"
          rows={3}
          cols={33}
          name="HERO_Paragraph"
          value={dictionary["HERO_Paragraph"]}
          onChange={updateValue}
          label="Çevirisi"
        />
      </div>
    </li>
  );
};

const FormItem = ({ updateValue, mainKey, dictionary, status, ...props }) => {
  const handleChange = (e) => {
    updateValue(e.target.name, e.target.value);
  };

  return (
    <li
      key={mainKey}
      className="overflow-hidden rounded-md bg-white p-6 gap-3 shadow grid-cols-2 sm:grid-cols-5 grid items-center"
    >
      {props.Options && (
        <>
          <div className="col-span-2">
            <p className="text-lg font-semibold">
              {props["OriginalQuestion"] || props["Original_Title"]}
            </p>
            <ul className="pl-8">
              {props.Options.map((option, index) => (
                <li key={index} className="text-sm list-decimal">
                  {option.OriginalLabel}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-3 grid-cols-3 grid gap-3">
            <TextInput
              name={mainKey + "_Translation"}
              value={dictionary[mainKey + "_Translation"]}
              onChange={handleChange}
              label="Soru Çevirisi"
            />
            {props.Options.map((option, index) => (
              <TextInput
                key={index}
                name={mainKey + "_Option_" + index}
                value={dictionary[mainKey + "_Option_" + index]}
                onChange={handleChange}
                label={"Seçenek " + (index + 1)}
              />
            ))}
            {status && (
              <p
                className={`mt-2 text-sm ${
                  status === "updating"
                    ? "text-blue-500"
                    : status === "done"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {status === "updating"
                  ? "Updating..."
                  : status === "done"
                  ? "Updated successfully!"
                  : "Update failed!"}
              </p>
            )}
          </div>
        </>
      )}
      {!props.Options && (
        <>
          <li
            key={mainKey}
            className="overflow-hidden rounded-md bg-white p-6 shadow col-span-full"
          >
            <TextareaInput
              className="resize-none"
              rows={3}
              cols={33}
              name={mainKey + "_Question"}
              value={dictionary[mainKey + "_Translation"]}
              onChange={handleChange}
              label={props["OriginalQuestion"] || props["Original_Title"]}
            />
            {status && (
              <p
                className={`mt-2 text-sm ${
                  status === "updating"
                    ? "text-blue-500"
                    : status === "done"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {status === "updating"
                  ? "Updating..."
                  : status === "done"
                  ? "Updated successfully!"
                  : "Update failed!"}
              </p>
            )}
          </li>
        </>
      )}
    </li>
  );
};

export default function Dictionary() {
  const { isocode } = useParams();
  const [dictionary, setDictionary] = useState({});
  const [statuses, setStatuses] = useState({}); // To track update statuses
  const [all = [], setAll] = useState(emptyValues);

  const defaultValues = {
    Words: emptyValues,
    Hero: emptyHeroValues,
    Paragraphs: emptyParagraphValues,
    Exercises: emptyExerciseValues,
    Pricing: emptyPricingValues,
    "Questions & Forms": formTranslations,
    Checkout: checkoutPageValues,
  };

  useEffect(() => {
    languageService.getDictionary(isocode).then((data) => {
      if (data) {
        //Assign
        setDictionary(data);
      }
    });

    console.log("Form Translations", formTranslations);
  }, [isocode]);

  // Debounced function to update dictionary value on the server
  //TODO : Error Handling
  const debouncedUpdateDictionaryValue = useCallback(
    debounce(async (key, value) => {
      setStatuses((prev) => ({ ...prev, [key]: "updating" }));

      const response = await languageService.updateDictionaryValue(
        isocode,
        key,
        value
      );

      console.log("Update response", response);

      if (!response) {
        setStatuses((prev) => ({ ...prev, [key]: "failed" }));
        // Clear the status after 1 second
        setTimeout(() => {
          setStatuses((prev) => {
            const { [key]: _, ...rest } = prev;
            return rest;
          });
        }, 1000);
      }
      setStatuses((prev) => ({ ...prev, [key]: "done" }));
      // Clear the status after 1 second
      setTimeout(() => {
        setStatuses((prev) => {
          const { [key]: _, ...rest } = prev;
          return rest;
        });
      }, 1000);
    }, 300),
    [isocode]
  );

  const updateValue = (key, value) => {
    // Update the local dictionary state
    setDictionary((prev) => ({ ...prev, [key]: value }));
    // Call the debounced server update
    debouncedUpdateDictionaryValue(key, value);
  };

  const tabs = [
    "Words",
    "Hero",
    "Paragraphs",
    "Pricing",
    "Questions & Forms",
    "Exercises",
    "Checkout",
    "Alerts & Modals",
  ];

  return (
    <DoctorLayout>
      <div className="p-5">
        <h1 className="text-xl font-semibold uppercase">
          {isocode} Dictionary
        </h1>

        <Tab.Group>
          <Tab.List className="my-2">
            {tabs.map((tab) => (
              <Tab
                onClick={() => {
                  setAll(defaultValues[tab]);
                }}
                className={({ selected }) =>
                  `text-gray-500 hover:text-cyan-700 rounded-md mx-1 py-1 px-2 sm:px-3 sm:py-2 text-sm font-medium ${
                    selected ? "bg-cyan-600 text-white hover:text-gray-200" : ""
                  }`
                }
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <hr className="my-3" />
            <ul className="grid grid-cols-3 gap-3 my-4">
              <Tab.Panel className="w-full col-span-full gap-3 sm:grid-cols-3 grid-cols-1 grid">
                {Object.keys(all).map((key) => (
                  <Item
                    key={key}
                    label={key}
                    value={dictionary[key]}
                    onChange={(e) => {
                      updateValue(key, e.target.value);
                    }}
                    name={key}
                    status={statuses[key]} // Pass the status of each key
                  />
                ))}
              </Tab.Panel>
              <Tab.Panel className="w-full col-span-full gap-3 grid-cols-1 grid">
                <HeroItems
                  setDictionary={setDictionary}
                  dictionary={dictionary}
                />
              </Tab.Panel>
              <Tab.Panel className="w-full col-span-full gap-3 grid-cols-1 grid">
                {Object.keys(all).map((key) => (
                  <ParagraphItem
                    key={key}
                    status={statuses[key]} // Pass the status of each key
                    mainKey={key}
                    dictionary={dictionary}
                    updateValue={updateValue}
                    {...all[key]}
                  />
                ))}
              </Tab.Panel>
              <Tab.Panel className="w-full col-span-full gap-3 grid-cols-1 grid">
                {pricingHeaders &&
                  Object.keys(pricingHeaders).map((key) => (
                    <Item
                      key={key}
                      label={key}
                      value={dictionary[key]}
                      onChange={(e) => {
                        updateValue(key, e.target.value);
                      }}
                      name={key}
                      status={statuses[key]} // Pass the status of each key
                    />
                  ))}
                {Object.keys(all).map((key) => (
                  <ParagraphItem
                    key={key}
                    status={statuses[key]} // Pass the status of each key
                    mainKey={key}
                    dictionary={dictionary}
                    updateValue={updateValue}
                    {...all[key]}
                  />
                ))}
              </Tab.Panel>
              <Tab.Panel className="w-full col-span-full gap-3 grid-cols-1 grid">
                {Object.keys(all).map((key, index) => (
                  <FormItem
                    key={index}
                    status={statuses[key]} // Pass the status of each key
                    mainKey={key}
                    dictionary={dictionary}
                    updateValue={updateValue}
                    {...all[key]}
                  />
                ))}
              </Tab.Panel>
              <Tab.Panel className="w-full col-span-full gap-3 grid-cols-1 grid">
                {Object.keys(all).map((key) => (
                  <ExerciseItem
                    key={key}
                    status={statuses[key]} // Pass the status of each key
                    mainKey={key}
                    dictionary={dictionary}
                    updateValue={updateValue}
                    {...all[key]}
                  />
                ))}
              </Tab.Panel>
              <Tab.Panel className="w-full col-span-full gap-3 sm:grid-cols-3 grid-cols-1 grid">
                {Object.keys(all).map((key) => (
                  <Item
                    key={key}
                    label={key}
                    value={dictionary[key]}
                    onChange={(e) => {
                      updateValue(key, e.target.value);
                    }}
                    name={key}
                    status={statuses[key]} // Pass the status of each key
                  />
                ))}
              </Tab.Panel>
            </ul>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </DoctorLayout>
  );
}
