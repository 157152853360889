import React from "react";
import { NavLink, useParams } from "react-router-dom";

const LangNavLink = ({ to, ...props }) => {
  const { lang } = useParams(); // Extract the current language from the URL

  // Prepend the current language to the `to` path
  const prefixedTo = `/${lang}${to.startsWith("/") ? to : `/${to}`}`;

  return <NavLink to={prefixedTo} {...props} />;
};

export default LangNavLink;
