import { Outlet, useParams } from "react-router-dom";
import { LanguageProvider } from "../context/Translator";

const LanguageLayout = () => {
  const { lang } = useParams();

  return (
    <LanguageProvider currentLanguage={lang}>
      <Outlet />
    </LanguageProvider>
  );
};

export default LanguageLayout;
