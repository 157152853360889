import React, { useState, Fragment, useEffect } from "react";
import Link from "../../../../services/multi-lang/components/linkWithLang";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3CenterLeftIcon,
  CogIcon,
  HomeIcon,
  XMarkIcon,
  DocumentIcon,
  CalculatorIcon,
  CreditCardIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useAuth } from "../../../../context/AuthContext";
import NavLink from "../../../../services/multi-lang/components/LangNavLink";
import { useLangNavigation } from "../../../../services/multi-lang/components/navigateWithLang";
import { useLanguage } from "../../../../services/multi-lang/context/Translator";
import LanguagePicker from "../../../../services/multi-lang/components/LanguagePickerFlags";

const placeholderImg = "/placeholder.jpeg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard({ children }) {
  const navigate = useLangNavigation();
  const { translate } = useLanguage();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { userData } = useAuth();

  const navigation = [
    { name: translate("Home"), href: "/", icon: HomeIcon },
    {
      name: translate("Danışanlarım"),
      href: "/dashboard",
      icon: UserGroupIcon,
    },
    {
      name: translate("Danışan Arşivim"),
      href: "/archive",
      icon: UserGroupIcon,
    },
    {
      name: translate("Metinler & Hesap Makinesi"),
      href: "/texts",
      icon: CalculatorIcon,
    },

    // TODO : Sayfa ve Entegrasyon Tamamlanınca Geri Eklenecek { name: "Billing", href: "/billing", icon: CreditCardIcon,},
  ];

  const secondaryNavigation = [
    { name: translate("Dosyalar"), href: "/soon", icon: DocumentIcon },
    { name: translate("Araçlar"), href: "/soon", icon: CogIcon },
  ];

  useEffect(() => {
    if (userData === null || userData === undefined || !userData) {
      console.log("User not found");
      navigate("/login");
      return;
    }

    if (userData?.role !== "doctor") {
      console.log("User is not a doctor");
      navigate("/pdashboard");
      return;
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-cyan-700 pb-4 pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="relative ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://firebasestorage.googleapis.com/v0/b/crocodil-9f989.appspot.com/o/Logos%2Flogo-v.png?alt=media&token=beb46bbe-5da9-41d8-b79d-5aca53dfc81c"
                    alt="Crocodil logo"
                  />
                </div>
                <nav
                  className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-cyan-800 text-white"
                              : "text-cyan-100 hover:bg-cyan-600 hover:text-white",
                            "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                          )
                        }
                        aria-current={item.current ? "page" : undefined}
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                    {userData.isEditor && (
                      <NavLink
                        to="/languages"
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-cyan-800 text-white"
                              : "text-cyan-100 hover:bg-cyan-600 hover:text-white",
                            "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                          )
                        }
                      >
                        <DocumentIcon className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200" />
                        {translate("Çeviriler & Diller")}
                      </NavLink>
                    )}
                  </div>
                  <div className="mt-6 pt-6">
                    <div className="space-y-1 px-2">
                      {secondaryNavigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-cyan-100 hover:bg-cyan-600 hover:text-white"
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 text-cyan-200"
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto bg-teal-700 pb-4 pt-5">
          <div className="flex flex-shrink-0 items-center px-4">
            <img
              className="h-8 w-auto"
              src="https://firebasestorage.googleapis.com/v0/b/crocodil-9f989.appspot.com/o/Logos%2Flogo-v.png?alt=media&token=beb46bbe-5da9-41d8-b79d-5aca53dfc81c"
              alt="Crocodil logo"
            />
          </div>
          <nav
            className="mt-5 flex flex-1 flex-col divide-y divide-cyan-800 overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="space-y-1 px-2">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? "bg-cyan-800 text-white"
                        : "text-cyan-100 hover:bg-cyan-600 hover:text-white",
                      "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                    )
                  }
                  aria-current={item.current ? "page" : undefined}
                >
                  <item.icon
                    className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                    aria-hidden="true"
                  />
                  {item.name}
                </NavLink>
              ))}
              {userData?.isEditor && (
                <NavLink
                  to="/languages"
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? "bg-cyan-800 text-white"
                        : "text-cyan-100 hover:bg-cyan-600 hover:text-white",
                      "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                    )
                  }
                >
                  <DocumentIcon className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200" />
                  {translate("Çeviriler & Diller")}
                </NavLink>
              )}
            </div>
            <div className="mt-6 pt-6">
              <div className="space-y-1 px-2">
                {secondaryNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white"
                  >
                    <item.icon
                      className="mr-4 h-6 w-6 text-cyan-200"
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="flex flex-1 flex-col lg:pl-64">
        <div className="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
            <div className="flex flex-1"></div>
            <div className="ml-4 flex items-center md:ml-6">
              <div>
                <LanguagePicker />
              </div>
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                    <span className="absolute -inset-1.5 lg:hidden" />
                    <img
                      className="h-8 w-8 rounded-full"
                      src={userData?.photoUrl || placeholderImg}
                      alt="Profile Photo"
                    />
                    <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                      <span className="sr-only">Open user menu for </span>
                      {userData?.firstName || "User"} {userData?.lastName}
                    </span>
                    <ChevronDownIcon
                      className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/logout"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          {translate("Çıkış Yap")}
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
