import React from "react";
import {
  TextInput,
  SelectOption,
  SelectInput,
  RadioGroup,
  RadioOption,
  CheckboxGroup,
  CheckboxOption,
  TextareaInput,
  DateInput,
} from "../../../components/inputs/Index";
import { useLanguage } from "../../../services/multi-lang/context/Translator";

export default function Form({ formik }) {
  const { translate } = useLanguage();
  const fields = [
    {
      name: "danisan_ad_soyad",
      label: translate("danisan_ad_soyad_Question"),
      type: "text",
    },
    {
      label: translate("cinsiyet_Translation"),
      name: "cinsiyet",
      options: [
        {
          label: translate("cinsiyet_Option_0"),
          value: "erkek",
        },
        {
          label: translate("cinsiyet_Option_1"),
          value: "kadın",
        },
      ],
      type: "select",
    },
    {
      label: translate("dogum_tarihi_Question"),
      name: "dogum_tarihi",
      type: "date",
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      {fields.map((field, index) => {
        switch (field.type) {
          case "text":
            return (
              <TextInput
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                name={field.name}
                key={index}
                label={translate(`${field.name}_Question`)}
              />
            );
          case "select":
            return (
              <SelectInput
                label={translate(`${field.name}_Translation`)}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              >
                {field.options.map((option, index) => (
                  <SelectOption key={index} value={option.value}>
                    {option.label}
                  </SelectOption>
                ))}
              </SelectInput>
            );
          case "date":
            return (
              <DateInput
                value={formik.values[field.name]}
                label={translate(`${field.name}_Question`)}
                onChange={formik.onChange}
              />
            );

          case "radio":
            return (
              <RadioGroup
                label={translate(`${field.name}_Translation`)}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              >
                {field.options.map((option, index) => (
                  <RadioOption key={index} value={option.value}>
                    {option.label}
                  </RadioOption>
                ))}
              </RadioGroup>
            );

          case "checkbox":
            return (
              <CheckboxGroup
                label={translate(`${field.name}_Translation`)}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              >
                {field.options.map((option, index) => (
                  <CheckboxOption key={index} value={option.value}>
                    {option.label}
                  </CheckboxOption>
                ))}
              </CheckboxGroup>
            );

          case "textarea":
            return (
              <TextareaInput
                name={field.name}
                key={index}
                label={translate(`${field.name}_Question`)}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              />
            );

          default:
            return null;
        }
      })}
    </div>
  );
}
