import { useNavigate } from "react-router-dom";
import { useLanguage } from "../context/Translator";

export const useLangNavigation = () => {
  const navigate = useNavigate();
  const { currentLanguage } = useLanguage();

  const navigateWithLang = (path, options) => {
    const prefixedPath = `/${currentLanguage}${
      path.startsWith("/") ? path : `/${path}`
    }`;
    navigate(prefixedPath, options);
  };

  return navigateWithLang;
};
