import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../context/Translator";

const LangLink = ({ to, ...props }) => {
  const { currentLanguage } = useLanguage();

  // Prepend the current language to the `to` path
  const prefixedTo = `/${currentLanguage}${to.startsWith("/") ? to : `/${to}`}`;

  return <Link to={prefixedTo} {...props} />;
};

export default LangLink;
